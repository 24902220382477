/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
import React, { useState, memo, useEffect, Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Components
import MenuSidebar from "../menu-sidebar";
const ChatIcon = loadable(() => import("../chat-icon"));
const AiBotIcon = loadable(() => import("../ai-bot-icon"));
const SnackBar = loadable(() => import("../snack-bar"));
const Lottie = loadable(() => import("react-lottie"), { ssr: false });

// Constant
import { EVENT_LABEL_MAP } from "./constant";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import { COOKIE_CONSTANTS, NUMBER } from "../../../constants/app-constants";

// Utils
import Filters from "../../../utils/filters-v2";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { getPrevAppliedFilters } from "../../../utils/helpers/prev-applied-filters";
import { getFilterUrlForResumeSession } from "../../../utils/helpers/filter-resume-session";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import parseCookie from "../../../utils/helpers/parse-cookie";

// Hooks
import useScrollDirection from "../../../hooks/scroll-direction";

// Images
import HomeIconBlue from "./images/home-icon-blue.svg";
import MenuIconBlue from "./images/menu-icon-blue.svg";
import SellIconBlue from "./images/sell-icon-blue.svg";
import LoanIconBlue from "./images/loan-icon-blue.svg";
import BuyIconBlue from "./images/buy-icon-blue.svg";
import ServiceIconBlue from "./images/service-icon-blue.svg";
import ValuationIconBlue from "./images/valuation-icon-blue.svg";
import CarCareIconBlue from "./images/car-care-icon-blue.svg";

import IconCreditCard from "./images/credit-card.png";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import { BuyIconGrey, HomeIconGrey, MenuIconGrey, SellIconGrey, LoanIconGrey, ValuationIconGrey, ServiceIconGrey, CarCareIconGrey } from "./img-constants";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";
import isEmpty from "../../../utils/helpers/is-empty";
import animationData from "./back-button.json";

const imageMap = {
    [ROUTE_NAME.MENU]: MenuIconGrey,
    [ROUTE_NAME.LISTING]: BuyIconGrey,
    [ROUTE_NAME.HOME]: HomeIconGrey,
    [ROUTE_NAME.SELL_ONLINE]: SellIconGrey,
    [ROUTE_NAME.CAR_LOAN]: LoanIconGrey,
    [ROUTE_NAME.BANK_VALUATION_CERTIFICATE]: ValuationIconGrey,
    [ROUTE_NAME.CAR_SERVICING]: ServiceIconGrey,
    [ROUTE_NAME.CAR_CARE]: CarCareIconGrey
};

const activeImageMap = {
    [ROUTE_NAME.MENU]: MenuIconBlue,
    [ROUTE_NAME.LISTING]: BuyIconBlue,
    [ROUTE_NAME.HOME]: HomeIconBlue,
    [ROUTE_NAME.SELL_ONLINE]: SellIconBlue,
    [ROUTE_NAME.CAR_LOAN]: LoanIconBlue,
    [ROUTE_NAME.BANK_VALUATION_CERTIFICATE]: ValuationIconBlue,
    [ROUTE_NAME.CAR_SERVICING]: ServiceIconBlue,
    [ROUTE_NAME.CAR_CARE]: CarCareIconBlue
};

const gaEventMap = {
    [ROUTE_NAME.LISTING]: b2cHomePageEvents.bottomNavBuyCarServiceSelect,
    [ROUTE_NAME.HOME]: b2cHomePageEvents.bottomNavHomePageServiceSelect,
    [ROUTE_NAME.SELL_ONLINE]: b2cHomePageEvents.bottomNavSellCarServiceSelect,
    [ROUTE_NAME.CAR_LOAN]: b2cHomePageEvents.bottomNavCarLoanServiceSelect,
    [ROUTE_NAME.BANK_VALUATION_CERTIFICATE]: b2cHomePageEvents.bottomNavValuationCertificatreServiceSelect,
    [ROUTE_NAME.CAR_SERVICING]: b2cHomePageEvents.bottomNavCarServicingServiceSelect,
    [ROUTE_NAME.CAR_CARE]: b2cHomePageEvents.bottomNavCarCareServiceSelect,
    [ROUTE_NAME.MENU]: b2cHomePageEvents.bottomNavMenuServiceSelect
};

const gaEventLabelMap = {
    [ROUTE_NAME.LISTING]: "buy_car_selected",
    [ROUTE_NAME.HOME]: "home_page_selected",
    [ROUTE_NAME.SELL_ONLINE]: "sell_car_selected",
    [ROUTE_NAME.CAR_LOAN]: "car_loan_selected",
    [ROUTE_NAME.BANK_VALUATION_CERTIFICATE]: "valuation_certificate_selected",
    [ROUTE_NAME.CAR_SERVICING]: "car_servicing_selected",
    [ROUTE_NAME.CAR_CARE]: "car_care_selected",
    [ROUTE_NAME.MENU]: "menu_selected"
};
const BottomNavigation = ({
    routeName,
    selectedCityCode,
    selectedCityName,
    reloadCarListConnect,
    isVisibleCB,
    searchQuery,
    typedQuery,
    isLoggedIn,
    showLoanBugetNudge,
    isShowCheckLoanBudgetCta = false,
    activeSaleConfig,
    bottomNavConfig,
    fetchBottomNavConfigConnect
}) => {
    const [showSidebar, setShowSidebar] = useState(false);

    const { customPush } = useCustomHistoryOperations();

    const scrolledUp = useScrollDirection({ threshold: 10 });
    const isShowSnackBar = activeSaleConfig?.active && !isLoggedIn && parseCookie(COOKIE_CONSTANTS.BOTTOM_SALE_NUDGE_NOTIFIER_SHOWN);
    const backButtonDefaultOptions = {
        loop: false,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const handleRedirection = useCallback(async ({ redirectionUrl, newRoute }, event) => {
        event.persist();
        await yieldToMainThread();
        if (showSidebar) setShowSidebar(false);
        if (newRoute === ROUTE_NAME.LISTING) {
            const filterData = getPrevAppliedFilters();
            if (filterData?.latestFilterUrl) {
                await customPush(getFilterUrlForResumeSession(filterData?.latestFilterUrl));
            } else {
                const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
                const { relativeURL } = filters.getListingPayload({
                    selectedFilters: [], filters: {}, options: {
                        ...((searchQuery || typedQuery) && {
                            search: searchQuery || typedQuery,
                            matchType: "EXACT"
                        })
                    }
                });
                reloadCarListConnect(true);
                await customPush(relativeURL);
            }
        } else {
            await customPush(redirectionUrl);
        }
        await yieldToMainThread(NUMBER.FIVE_HUNDRED);
        scrollToTop();
        if (newRoute !== routeName || showSidebar) {
            trackPlatformCustomEventsAEV2(b2cHomePageEvents.superAppHpBottomNavSelect, {
                eventLabel: EVENT_LABEL_MAP?.[newRoute] || ""
            });
        }
        if (newRoute === ROUTE_NAME.CAR_LOAN) {
            trackPlatformCustomEventsAEV2(b2cAllPageEvents.carAffordabilityCarLoanEntry, { eventLabel: "bottom_nav" });
        }

        trackPlatformCustomEventsAEV2(gaEventMap[newRoute], {eventLabel: !showSidebar ? gaEventLabelMap[routeName] : gaEventLabelMap[ROUTE_NAME.MENU]});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeName, showSidebar, selectedCityCode]);

    const { pageSections = [] } = bottomNavConfig || {};

    const currentPageConfig = pageSections.find(pageSection => pageSection?.pageSection?.name === routeName)?.pageSection || {};

    const { sectionData = [] } = currentPageConfig || {};

    const handleClick = async () => {
        if (showSidebar) return;
        await yieldToMainThread();
        setShowSidebar(true);
        trackPlatformCustomEventsAEV2(b2cHomePageEvents.superAppHpBottomNavSelect, { eventLabel: EVENT_LABEL_MAP.MENU });
        trackPlatformCustomEventsAEV2(gaEventMap[ROUTE_NAME.MENU], {eventLabel: gaEventLabelMap[routeName]});
    };

    useEffect(() => {
        if (typeof isVisibleCB === "function") {
            isVisibleCB(scrolledUp);
        }
    }, [isVisibleCB, scrolledUp]);

    useEffect(() => {
        if (bottomNavConfig?.isDefaultConfig || isEmpty(bottomNavConfig)) {
            fetchBottomNavConfigConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {[ROUTE_NAME.HOME, ROUTE_NAME.CAR_LOAN].includes(routeName) &&
                <React.Fragment>
                    <ChatIcon
                        route={{ name: routeName }}
                        isBottomNavVisible={scrolledUp}
                        isShowCheckLoanBudgetCta={isShowCheckLoanBudgetCta}
                        isAnotherCTAVisible={isShowSnackBar && routeName === ROUTE_NAME.HOME}
                    />{routeName !== ROUTE_NAME.CAR_LOAN && <AiBotIcon
                        isBottomNavVisible={scrolledUp}
                        isShowCheckLoanBudgetCta={isShowCheckLoanBudgetCta}
                        isAnotherCTAVisible={isShowSnackBar && routeName === ROUTE_NAME.HOME}
                    />}
                </React.Fragment>
            }
            {isShowSnackBar && routeName === ROUTE_NAME.HOME &&
                <SnackBar
                    source={routeName}
                    isBottomNavVisible={scrolledUp}
                    show={isShowSnackBar}
                />
            }
            <div styleName={`styles.bottomNavWrap ${(scrolledUp || showSidebar) ? "" : "styles.bottomNavWrapHide"} ${isLoggedIn ? "" : "styles.noBoxShadow"} ${isShowSnackBar ? "styles.removeShadow" : ""}`}>
                <ul>
                    {(sectionData || []).map(sectionInfo => {
                        const { title, primaryCtaUrl, primaryCtaText } = sectionInfo || {};
                        if (title === "back") {
                            return (<li key={`bottom_nav_${title}`}
                                onClick={handleRedirection.bind(null, { redirectionUrl: primaryCtaUrl, newRoute: title })}>
                                <div styleName={"styles.backButton"}>
                                    <Lottie
                                        options={backButtonDefaultOptions}
                                        height={NUMBER.THIRTY_TWO}
                                        width={NUMBER.SIXTY_FOUR}
                                    />
                                </div>

                                <span>{primaryCtaText}</span>
                            </li>);
                        }
                        return (<li
                            onClick={title === ROUTE_NAME.MENU ? handleClick : handleRedirection.bind(null, { redirectionUrl: primaryCtaUrl, newRoute: title })}
                            styleName={(!showSidebar && routeName === title) || (showSidebar && title === ROUTE_NAME.MENU) ? "styles.active" : ""}
                            key={`bottom_nav_${title}`}
                        >
                            {(!showSidebar && routeName === title) || (showSidebar && title === ROUTE_NAME.MENU) ? <img src={activeImageMap[title]} width="64" height="32" alt="" /> : <div dangerouslySetInnerHTML={{ __html: imageMap[title] }} />}
                            <span>{primaryCtaText}</span>
                            {showLoanBugetNudge && title?.toLowerCase() === "loan" && <div styleName={"styles.loanBudgetNudge"}>
                                <img src={IconCreditCard} alt="" width={24} height={24} />
                                Check loan budget
                            </div>}
                        </li>);
                    })}
                </ul>
            </div>
            <MenuSidebar
                isVisible={showSidebar}
                setShowSidebar={setShowSidebar}
                routeName={routeName}
            />
        </Fragment>
    );
};

BottomNavigation.propTypes = {
    routeName: PropTypes.string,
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    isVisibleCB: PropTypes.func,
    searchQuery: PropTypes.string,
    typedQuery: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    showLoanBugetNudge: PropTypes.bool,
    isShowCheckLoanBudgetCta: PropTypes.bool,
    fetchBottomNavConfigConnect: PropTypes.func,
    bottomNavConfig: PropTypes.object,
    activeSaleConfig: PropTypes.object
};

export default memo(BottomNavigation);
