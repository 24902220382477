import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reloadCarList } from "../car-listing/actions";
import BottomNavigation from "./component";
import { fetchBottomNavConfig } from "../config-ssr/actions";

const mapStateToProps = ({
    cities: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    },
    filters: {
        searchResultTrackingData: {
            typedQuery,
            searchQuery
        }
    },
    user: {
        isLoggedIn, showLoanBugetNudge
    },
    myBookings: {
        bookings: { cars: snackCards = [] },
        isFetching: fetchingSnackCards,
        snackCardsFetched
    },
    config: {
        activeSaleConfig,
        bottomNavConfig
    }
}) => ({
    selectedCityCode,
    selectedCityName,
    searchQuery,
    typedQuery,
    isLoggedIn,
    showLoanBugetNudge,
    snackCards,
    fetchingSnackCards,
    snackCardsFetched,
    activeSaleConfig,
    bottomNavConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    fetchBottomNavConfigConnect: fetchBottomNavConfig
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavigation);
